  import React from "react"
import { Link } from "gatsby"
import "./frontpage.scss"
import "../styles/readText.scss"
import { Parallax } from 'react-scroll-parallax'
import BackgroundImage from 'gatsby-background-image'
import  BgImage  from "../components/BgImage"
import { useStaticQuery, graphql } from "gatsby"
import LottieLogo from '../logo/lottielogo'

const Frontpage = (props) => {
  // image2: file(relativePath: { eq: "cykel.png" }) {
  //   ...heroImage
  // }

  const images = useStaticQuery(graphql`
   query {
     image1: file(relativePath: { eq: "forelasning.jpg" }) {
       ...heroImage
     }
     image2: file(relativePath: { eq: "susanne_action.jpg" }) {
       ...heroImage
     }
     headerImage: file(relativePath: { eq: "header.jpg" }) {
       ...heroImage
     }
   }
 `)


  let boxes = (
    <div className="container wide ">
      <section id="showMenu" className="boxes flex--row scroll-point" offset="0%">
        <div className="box bg--pink fade-in">
          <Link to="/forelasningar">
            <h2 className="title--medium">Föreläsningar.</h2>
            <p className="label-for">Företag & föreningar.</p>
          </Link>
        </div>
        
        <div className="box bg--blue fade-in">
          <Link to="/forelasning-coachning">
            <h2 className="title--medium">Föreläsning + individuell coachning.</h2>
            <p className="label-for">Företag.</p>
          </Link>
        </div>
        {/* <div className="box bg--orange fade-in">
          <Link to="/individuell-coachning">
            <h2 className="title--medium">Individuell coachning.</h2>
            <p className="label-for">Privatpersoner.</p>
          </Link>
        </div> */}
        <div className="box bg--orange fade-in">
          <a href="https://dunu.se" target="_blank" rel="noreferrer">
            <h2 className="title--medium">Hållbar hälsa.</h2>
            <p className="label-for">DU.NU</p>
          </a>
        </div>
      </section>
    </div>
  )

  return(
    <div className="page frontpage">

      <BackgroundImage
        fluid={images.headerImage.childImageSharp.fluid}
        className="page-header banner"
      >
      <Parallax y={[-40, 0]}>

        <LottieLogo />
      </Parallax>

      <div className="container wide">
        <div className="content">
          <h1 className="title--large" dangerouslySetInnerHTML={ { __html:  props.post.title } } />
        </div>
      </div>

      </BackgroundImage>

      { boxes }
      <section className="main scroll-point" offset="20%">
        <div className="container narrow flex--row flex--end">

          <aside className="intro scroll-point">
            <h2 className="title--large fade-in">Susanne Dalsätt</h2>
            <h3 className="title--large fade-in colorize">Hälsoinspiratör.</h3>
          </aside>
          <div className="content fade-in fade-this" dangerouslySetInnerHTML={{__html: props.post.content}} />
        </div>
      </section>
      <section className="heroes">

        <BgImage img={images.image1.childImageSharp.fluid} align="left" scrollPointMargin={true}>
          <p>
            Ibland behöver vi alla en skjuts i rätt riktning. Vill du hjälpa dina medarbetare att hitta en sundare livsstil genom rätt näring och bra kost?
          </p>
          <Parallax y={[-25, 35]}>
            <a className="button bg--pink" href="/forelasning-coachning/">Boka föreläsning och/eller individuell coachning för företag</a>
          </Parallax>
        </BgImage>

        <BgImage img={images.image2.childImageSharp.fluid} align="right" scrollPointMargin={true} wave>
          <p>
            Vad är ditt mål med din träning? Att väcka motivationen och hitta rörelsen? Ta första steget mot ett ultralopp? Eller något däremellan? Jag kan hjälpa dig framåt.
          </p>
          <Parallax y={[-25, 35]}>
            <a className="button bg--orange" href="https://dunu.se" target="_blank" rel="noreferrer">Läs mer om hållbar hälsa på DUNU.se</a>
          </Parallax>

        </BgImage>



      </section>
    </div>
  )
}

export default Frontpage

// Kan bli aktuellt när fler än en föreläsning finns
// {props.hasTalks &&
//   props.hasTalks.map(data => {
//
//     return (
//       <div key={data.post_title}>
//         <Link to={`/forelasningar/${data.post_name}`} ><h2>{data.post_title}</h2></Link>
//         <p>{data.post_excerpt}</p>
//         <img src={data.featured_image} alt={data.post_name} />
//       </div>
//     )
//   })
// }
