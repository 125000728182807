import React from "react";

import { Parallax } from 'react-scroll-parallax'
import Img from "gatsby-image"
// import "../styles/Coaching.scss"

import PageHeadline from '../components/PageHeadline'
import {AttachedImages} from '../Functions/AttachedImages'
import ImageWithLogo from '../components/ImageWithLogo'
import ContactBlock from '../components/ContactBlock'


const Coaching = (props) => {

  const images = AttachedImages();
//  console.log('images',images)


 const contentImage = <Img className="content-image" fluid={images.individuell.childImageSharp.fluid} alt="Individuell coachning" />


 const lecture = {
   name: props.post.acf.name,
   category: props.post.acf.category,
   preamble: props.post.acf.preamble,
   quote: props.post.acf.quote,
   text: props.post.acf.lower_text
 }


  return(
    <div className="page coaching">

      <PageHeadline data={props.post}/>
        <article className="bg--orange bg--right container scroll-point">
          <div className="content">
            <div className="content__box align--left fade-in">

              <div dangerouslySetInnerHTML={{__html: props.post.content}} />
              <p dangerouslySetInnerHTML={{__html: lecture.text}} />
            </div>


            <Parallax className="parallax-image" y={[-60,-80]}>
              <ImageWithLogo img={contentImage} alignLogo="right" />
            </Parallax>




          </div>

        </article>



      <ContactBlock post={props.post} />
    </div>
  )
}



export default Coaching

// Kan bli aktuellt när fler än en föreläsning finns
// {props.hasTalks &&
//   props.hasTalks.map(data => {
//
//     return (
//       <div key={data.post_title}>
//         <Link to={`/Coaching/${data.post_name}`} ><h2>{data.post_title}</h2></Link>
//         <p>{data.post_excerpt}</p>
//         <img src={data.featured_image} alt={data.post_name} />
//       </div>
//     )
//   })
// }
