import React from "react"
import { Parallax } from 'react-scroll-parallax'
import Img from "gatsby-image"
// import Img from "gatsby-plugin-image"
import "./regularpage.scss"
import {AttachedImages} from '../Functions/AttachedImages'

import Puff from '../components/puff'
import PageHeadline from '../components/PageHeadline'
import ContactBlock from '../components/ContactBlock'
import ImageWithLogo from '../components/ImageWithLogo'

const RegularPage = (props) => {

  const images = AttachedImages();
  const featured_image = props.post.featured_media ? props.post.featured_media.source_url : false;

  console.log('FEATURED IMAGE: ', props.post.featured_media.localFile.childImageSharp.fluid.originalImg);
  let contentImage = featured_image ? <img alt={props.post.title} src={`${props.post.featured_media.source_url}`} /> : <Img className="content-image" fluid={images.forelasningCoachning.childImageSharp.fluid} alt="Föreläsning och individuell coachning" />
  // let puff = <Puff page={props.post.slug} />

  // const mainClass = " container bg--left bg--blue ";
  // const titleLowerCase = props.post.title.toLowerCase()


  return(
    <div className="page regularpage">
      <PageHeadline data={props.post}/>


        <article className="container bg--left bg--blue scroll-point">

          <div className="flex--row content ">
            <Parallax className="parallax-image flex--row__col " y={[-60,-120]}>
              <ImageWithLogo img={contentImage} alignLogo="left" />
            </Parallax>
            <div className=" flex--row__col content__box content__box--padding align--right fade-in">
              <div dangerouslySetInnerHTML={{__html: props.post.content}} />
            </div>
          </div>

        </article>

      <Puff block page={props.post.slug} />
      <ContactBlock post={props.post} />

    </div>
  )
}

export default RegularPage

// Kan bli aktuellt när fler än en föreläsning finns
// {props.hasTalks &&
//   props.hasTalks.map(data => {
//
//     return (
//       <div key={data.post_title}>
//         <Link to={`/forelasningar/${data.post_name}`} ><h2>{data.post_title}</h2></Link>
//         <p>{data.post_excerpt}</p>
//         <img src={data.featured_image} alt={data.post_name} />
//       </div>
//     )
//   })
// }
