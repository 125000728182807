import React, {useEffect, useState} from "react"
import Layout from "../components/layout"

import { ParallaxProvider } from 'react-scroll-parallax';
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Frontpage from "./frontpage"
import RegularPage from "./regularpage"
import ContactPage from "./contactpage"
import Coaching from "./coaching"
import Forelasningar from "./forelasningar"
import '../styles/_layout.scss';


export default ({ data }) => {
  const [loaded, changeStatus] = useState(false);


const post = data.allWordpressPage.nodes[0];
const lectures = data.allWordpressWpTalk.nodes;

console.log('post: ', post);


  var StrippedString = post.title.replace(/(<([^>]+)>)/ig,"");
  let seotitle = StrippedString;

  let main
  if(post.template === 'template-frontpage.php'){
    main = <Frontpage post={post} />
  }else if(post.slug === 'kontakt'){
    main = <ContactPage post={post} />
  }
  else if(post.slug === 'forelasningar'){
    main = <Forelasningar post={post} lectures={lectures}/>
  }
  else if(post.slug === 'individuell-coachning'){
    main = <Coaching post={post} />
  }
  else{
    main = <RegularPage post={post} />
  }

  useEffect(() => {
    changeStatus(true)
  }, []);

  return (
    <>
    <div className={loaded ? "page-loaded":  "page-loading"}>
      <Layout slug={post.slug}>
        <SEO title={seotitle} />
        <ParallaxProvider>
          {main}
        </ParallaxProvider>
      </Layout>
      </div>
      <div className={loaded ? "loader--loaded":  "loader--loading"} />
    </>
  )
}


export const query = graphql`
  query($slug: String!){
    allWordpressPage(filter: {slug: {eq: $slug}}) {
      nodes {
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        acf {
          ingress
          underrubrik
          lower_text
          quote
          name
          bg_color
          preamble
          category
          contact_options {
            contact_options_value
            contact_options_name
          }
          social_media {
            social_media_link
            social_media_name
          }
        }
        template
        title
        slug
        content
      }
    }
    allWordpressWpTalk(sort: {order: ASC, fields: menu_order}) {
      nodes {
        content
        slug
        title
        excerpt
        acf {
          bg_color
        }
        featured_media {
          source_url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

//
// export const query = graphql`
//   query($slug: String!, $order: [SortOrderEnum] = ASC){
//     allWordpressPage(filter: {slug: {eq: $slug}}) {
//       edges {
//         node {
//           title
//           content
//           template
//           slug
//           acf {
//             ingress
//             underrubrik
//             lower_text
//             quote
//             name
//             preamble
//             category
//             contact_options {
//               contact_options_value
//               contact_options_name
//             }
//             social_media {
//               social_media_link
//               social_media_name
//             }
//           }
//           featured_media {
//             localFile {
//               childImageSharp {
//                 fluid {
//                   base64
//                   tracedSVG
//                   aspectRatio
//                   src
//                   srcSet
//                   srcWebp
//                   srcSetWebp
//                   sizes
//                   originalImg
//                   originalName
//                   presentationWidth
//                   presentationHeight
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
