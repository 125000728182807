import React, {Component} from "react"

import anime from 'animejs';
import InView from '../Functions/InView.js';


class Wave extends Component {
  constructor(props){
    super(props);

    this.ref = React.createRef();

    this.handleScroll = this.handleScroll.bind(this);
    this.animation = this.animation.bind(this);

    this.state = {
      animated: false
    }
  }

  animation(){
    anime(
      {
        targets: '.wave path',
        loop: false,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 1700,
        delay: 1500
      }
    );

    this.setState({
      animated:true
    })
  }

  handleScroll(){
    let element = this.ref.current;

    if( InView(element, 0) && !this.state.animated) {
      console.log('animate');
      this.animation()
    }
  }


  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll)
  }

  render(){
    let hidden = this.state.animated ? "" : " hide";
    return (
      <svg
        ref={this.ref}
        className={"scroll-point wave" + hidden}
        viewBox="0 0 475.13 56.38"
      enableBackground="new 0 0 475.13 56.38"

    >

      <style>
        {
          ".prefix__st0{display:none}.prefix__st4{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}"
        }
      </style>

      <g id="prefix__Lager_2">
        <g id="prefix__Page-1_1_">
          <g id="prefix__startsida_1_" transform="translate(0 -3540)">
            <g id="prefix__Group-3_1_" transform="translate(-368 3540)">
              <path
                d="M368 51.38c4.6 0 8.42-1.53 11.7-4.68 3.31-3.17 5.95-7.99 7.63-13.91 2.33-8.23 6.07-14.84 11.09-19.64C404.02 7.82 411.09 5 418.86 5c8.02 0 15.49 3.06 21.04 8.63 5.51 5.52 8.55 12.93 8.57 20.87v.03c0 4.61 1.74 8.85 4.89 11.98 3.15 3.14 7.44 4.87 12.07 4.87 4.59 0 8.43-1.53 11.7-4.68 3.3-3.17 5.94-7.99 7.62-13.91 2.34-8.23 6.07-14.99 11.1-19.78C501.44 7.67 508.51 5 516.28 5c8.02 0 15.49 3.06 21.03 8.63 5.52 5.52 8.56 12.93 8.58 20.87v.1c.02 4.58 1.75 8.81 4.88 11.92 3.14 3.14 7.43 4.87 12.06 4.87 4.6 0 8.42-1.53 11.7-4.68 3.31-3.17 5.95-7.99 7.63-13.91 2.33-8.23 6.07-14.84 11.09-19.64C598.84 7.82 605.91 5 613.68 5c8.02 0 15.49 3.06 21.04 8.63 5.51 5.52 8.55 12.93 8.57 20.87v.1c.02 4.58 1.78 8.81 4.91 11.92 3.15 3.14 7.44 4.87 12.07 4.87 4.59 0 8.42-1.53 11.7-4.68 3.3-3.17 5.94-7.99 7.62-13.91 2.34-8.23 6.07-14.84 11.1-19.64C696.29 7.82 703.35 5 711.12 5c8.02 0 15.49 3.06 21.03 8.63 5.52 5.52 8.56 12.93 8.58 20.87v.1c.02 4.58 1.75 8.81 4.88 11.92 3.14 3.14 7.43 4.87 12.06 4.87 4.6 0 8.42-1.53 11.7-4.68 3.31-3.17 5.95-7.99 7.63-13.91 2.33-8.23 6.07-14.84 11.09-19.64C793.69 7.82 800.75 5 808.52 5c8.02 0 15.49 3.06 21.04 8.63 5.53 5.54 8.57 12.99 8.57 20.97"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="none"
                stroke="#fff"
                strokeWidth={10}
                strokeMiterlimit={100}
              />
              <defs>
                <filter
                  id="prefix__Adobe_OpacityMaskFilter"
                  filterUnits="userSpaceOnUse"
                  x={435.8}
                  y={27.58}
                  width={0}
                  height={0}
                >
                  <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
              </defs>
              <mask
                maskUnits="userSpaceOnUse"
                x={435.8}
                y={27.58}
                width={0}
                height={0}
                id="prefix__mask-2_7_"
              >
                <g filter="url(#prefix__Adobe_OpacityMaskFilter)">
                  <path
                    id="prefix__path-1_7_"
                    className="prefix__st4"
                    d="M46.13-2.02h792v59h-792z"
                  />
                </g>
              </mask>
              <defs>
                <filter
                  id="prefix__Adobe_OpacityMaskFilter_1_"
                  filterUnits="userSpaceOnUse"
                  x={533.22}
                  y={27.58}
                  width={0}
                  height={0}
                >
                  <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
              </defs>
              <mask
                maskUnits="userSpaceOnUse"
                x={533.22}
                y={27.58}
                width={0}
                height={0}
                id="prefix__mask-2_5_"
              >
                <g filter="url(#prefix__Adobe_OpacityMaskFilter_1_)">
                  <path
                    id="prefix__path-1_5_"
                    className="prefix__st4"
                    d="M46.13-2.02h792v59h-792z"
                  />
                </g>
              </mask>
              <defs>
                <filter
                  id="prefix__Adobe_OpacityMaskFilter_2_"
                  filterUnits="userSpaceOnUse"
                  x={630.62}
                  y={27.58}
                  width={0}
                  height={0}
                >
                  <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
              </defs>
              <mask
                maskUnits="userSpaceOnUse"
                x={630.62}
                y={27.58}
                width={0}
                height={0}
                id="prefix__mask-2_4_"
              >
                <g filter="url(#prefix__Adobe_OpacityMaskFilter_2_)">
                  <path
                    id="prefix__path-1_4_"
                    className="prefix__st4"
                    d="M46.13-2.02h792v59h-792z"
                  />
                </g>
              </mask>
              <defs>
                <filter
                  id="prefix__Adobe_OpacityMaskFilter_3_"
                  filterUnits="userSpaceOnUse"
                  x={728.07}
                  y={27.58}
                  width={0}
                  height={0}
                >
                  <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
              </defs>
              <mask
                maskUnits="userSpaceOnUse"
                x={728.07}
                y={27.58}
                width={0}
                height={0}
                id="prefix__mask-2_3_"
              >
                <g filter="url(#prefix__Adobe_OpacityMaskFilter_3_)">
                  <path
                    id="prefix__path-1_3_"
                    className="prefix__st4"
                    d="M46.13-2.02h792v59h-792z"
                  />
                </g>
              </mask>
              <defs>
                <filter
                  id="prefix__Adobe_OpacityMaskFilter_4_"
                  filterUnits="userSpaceOnUse"
                  x={820.57}
                  y={15.5}
                  width={0}
                  height={0}
                >
                  <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                </filter>
              </defs>
              <mask
                maskUnits="userSpaceOnUse"
                x={820.57}
                y={15.5}
                width={0}
                height={0}
                id="prefix__mask-2_2_"
              >
                <g filter="url(#prefix__Adobe_OpacityMaskFilter_4_)">
                  <path
                    id="prefix__path-1_2_"
                    className="prefix__st4"
                    d="M46.13-2.02h792v59h-792z"
                  />
                </g>
              </mask>
            </g>
          </g>
        </g>
      </g>
    </svg>
    )
  }
}

export default Wave
