


import React from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax'
import  Wave  from "./Wave"

import '../styles/BgImage.scss'

const BgImage = ( props) => {

  const align = props.align || false;
  const scrollPointMargin = props.scrollPointMargin ? " scroll-point--margin" : false;

  return (
    <div className={"bg-image scroll-point" + scrollPointMargin}>



        <img src={props.img.src} className="bg-image__img" alt="" />

        {props.wave &&
          <Wave />
        }

      <div className={props.align ? "inner bg-image__content--align-"+ align : "inner bg-image__content"}>
      <Parallax y={[5, -25]} >

        <div className="bg-image__content__box">

          { props.children  }

        </div>
        </Parallax>

      </div>
    </div>
  )
};
BgImage.propTypes = {
  img: PropTypes.object.isRequired,
};
export default BgImage;
