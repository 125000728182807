import React from "react";
import Img from "gatsby-image"
import "../styles/forelasningar.scss"
import PageHeadline from '../components/PageHeadline'
import Puff from '../components/puff'
import ContactBlock from '../components/ContactBlock'
import {AttachedImages} from '../Functions/AttachedImages'

const Forelasningar = ({post, ...props}) => {

  const images = AttachedImages();

  const imgProps = {
    objectFit: 'cover'
  };

  return(
    <div className="page forelasningar">

      <PageHeadline data={post}/>

      <article className="container">
        <div className="scroll-point">

          <div className="content">


            {props.lectures &&
            <div className="lectures">
             { props.lectures.map((data, i) => {
               console.log('data', data.featured_media.localFile.childImageSharp.fluid);
                const featured_image = data.featured_media ? data.featured_media.source_url : false;
               const imageRes = data.featured_media.localFile.childImageSharp.fluid;
                // const contentImage = featured_image ? <img alt={data.title} style={imgProps} src={`${data.featured_media.source_url}`} /> : <Img style={imgProps} className="content-image" fluid={images.forelasning.childImageSharp.fluid} alt="Föreläsning och individuell coachning" />;
               const contentImage = featured_image ? <img alt={data.title} style={imgProps} className="content-image" src={imageRes.src} /> : <Img style={imgProps} className="content-image" fluid={images.forelasning.childImageSharp.fluid} alt="Föreläsning och individuell coachning" />;

                return (
                  <div key={`${data.title}-${i}`} className="lectures__item">
                    {contentImage}             
                    <Puff 
                      color={data.acf ? data.acf.bg_color : false}
                      link={`/forelasningar/${data.slug}`}
                      title={data.title}
                      text={data.excerpt}
                    />

                  </div>
                )
              })}
            </div>
            }


            

          
          </div>

        </div>

      </article>

      <Puff block page={post.slug} />


      <ContactBlock post={post} />
    </div>
  )
}



export default Forelasningar

// Kan bli aktuellt när fler än en föreläsning finns
// {props.lectures &&
//   props.lectures.map(data => {
//
//     return (
//       <div key={data.post_title}>
//         <Link to={`/forelasningar/${data.post_name}`} ><h2>{data.post_title}</h2></Link>
//         <p>{data.post_excerpt}</p>
//         <img src={data.featured_image} alt={data.post_name} />
//       </div>
//     )
//   })
// }
