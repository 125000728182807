import React, { Component } from 'react';

function getScrollPos(){
  const doc = typeof document !== `undefined` ? document : null;
  if(!doc)return false;

  return (window.scrollY !== undefined) ? window.scrollY : doc.documentElement.scrollTop;

}

function calcWindowSize(){
  const doc = typeof document !== `undefined` ? document : null;
  if(!doc)return false;

  return {
    pageHeight: doc.body.offsetHeight,
    height: window.innerHeight,
    width: doc.body.offsetWidth
  }

}

var WindowProps = {

  SIZE: calcWindowSize(),
  SCROLLTOP: getScrollPos(),

  handleScroll(){
    this.SCROLLTOP = getScrollPos();
  },
  updateSize(){
    this.SIZE = calcWindowSize();
  },
  componentDidMount(){
    console.log('did mount');
  }
}
export default WindowProps;
