import React from "react"
import { Parallax } from 'react-scroll-parallax'

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/contactpage.scss"
import LottieLogo from "../logo/lottielogo"
import PageForm from "../components/form"
import  Wave  from "../components/Wave"
import {ListContactInfo} from '../Functions/ListContactInfo.js'

const ContactPage = (props) => {
  let contacts = ListContactInfo();

  const images = useStaticQuery(graphql`
   query {
     kontakt: file(relativePath: { eq: "kontakt-small.jpg" }) {
       ...heroImage
     }
   }
 `)

//  let color = "bg--blue";

//  const mainClass = "main container " + color
//  const titleLowerCase = props.post.title.toLowerCase()

  return(
    <div id="showMenu" className="page contactpage">

      <article className="bg--yellow bg--center container scroll-point">
        <div className=" flex--row">
          <div className="content flex--row__co">
            <h1 className="title--large" dangerouslySetInnerHTML={ { __html:  props.post.title } } />
            <Parallax className="logo" y={[-10, 10]} >
              <LottieLogo />
            </Parallax>
            { props.post.acf.underrubrik &&
              <h2 dangerouslySetInnerHTML={ { __html: props.post.acf.underrubrik } } /> }
            { props.post.acf.ingress &&
              <p className="preamble" dangerouslySetInnerHTML={{__html: props.post.acf.ingress}} /> }
              <div dangerouslySetInnerHTML={{__html: props.post.content}} />


              <div className="flex--row flex--start">
              {
                Object.keys(contacts.contacts).map((key)=>(
                  <a className="link-arrow" href={contacts.contacts[key].value}>{contacts.contacts[key].name}</a>
                ))
              }
              </div>
          </div>
          <section className="form flex--row__cl">
            <PageForm page={props.post.slug} />
          </section>
        </div>

      </article>
      <div className="container  flex--row flex--end scroll-point">
        <Parallax className="contact-img" y={[-10, -40]} >
        <div className="rel">
          <Wave inverted />
          <Img className="content-image" fluid={images.kontakt.childImageSharp.fluid} alt="En glad kvinna" />
        </div>
        </Parallax>
      </div>

    </div>
  )
}

export default ContactPage
